<template>
  <div class="setupAccount">
    <el-dialog
      title="事件重新回传"
      width="480px"
      :visible="reSendBackShow"
      @close="toggleVisible(false)"
      append-to-body
      v-loading="loading"
    >
      <el-form
        :model="formData"
        label-suffix="："
        label-position="top"
      >
        <el-form-item label="回传事件范围">
          <el-date-picker
            v-model="time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="回传事件类型">
          <el-radio-group v-model="formData.supplementType">
            <el-radio label="ALL">全部事件重新回传</el-radio>
            <el-radio label="FAILED">失败事件重新回传</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div
        solt="footer"
        class="dp-flex justify-end"
      >
        <el-button
          @click="toggleVisible(false)"
          :loading="loading"
          >取消</el-button
        >
        <el-button
          type="primary"
          @click="sure"
          :loading="loading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { resendBack } from '@/api/google/account';
export default {
  props: {
    reSendBackShow: Boolean,
    utmSource: {
      type: String,
      default: 'GMALL3',
    },
    accountId: {
      type: String | Number,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      formData: {
        supplementType: 'FAILED',
      },
      time: [moment().subtract(1, 'day').format('YYYY-MM-DD'), moment().subtract(1, 'day').format('YYYY-MM-DD')],
      pickerOptions: {
        disabledDate(time) {
          const today = new Date();
          const ninetyDaysAgo = new Date();
          ninetyDaysAgo.setDate(today.getDate() - 90);
          // 禁用今天之后的日期和90天前的日期
          return time.getTime() > today.getTime() || time.getTime() < ninetyDaysAgo.getTime();
        },
      },
    };
  },
  methods: {
    toggleVisible(bool) {
      if (this.loading) return;
      this.$emit('update:reSendBackShow', bool);
    },
    sure() {
      let params = {
        accountIdList: [this.accountId],
        startDate: this.time[0],
        endDate: this.time[1],
        conversionType: 'PURCHASE',
        utmSource: this.utmSource,
        supplementType: 'FAILED',
      };
      this.loading = true;
      resendBack(params)
        .finally(() => {
          this.loading = false;
        })
        .then((res) => {
          if (res.code == 0) {
            this.$message.success(this.isOne ? '设置成功' : '重新回传已经提交，请在回传结果中关注回传结果');
            setTimeout(() => {
              this.toggleVisible(false);
            }, 1000);
          }
        });
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.setupAccount {
  ::v-deep .el-dialog {
    border-radius: 10px;
  }
  .tips {
    font-size: 12px;
    color: #999;
    height: 20px;
    line-height: 20px;
  }
}
</style>
